import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../style/main.css';

const Main = () => {
	const [backgroundImage, setBackgroundImage] = useState('');
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const [siteData, setSiteData] = useState(null);
	const [toggleStates, setToggleStates] = useState([]);

	const images = [
		'./bgs/alps.jpg', './bgs/amoungstShrubs.jpg', './bgs/artgallery4.jpg',
		'./bgs/redwoordForest.jpg', './bgs/graysuitpark.jpg', './bgs/italyCrow.jpg',
		'./bgs/logowalk.jpg', './bgs/museumwalkway.jpg', './bgs/redwoordForest.jpg',
		'./bgs/sanddunes.jpeg', './bgs/tigerLily.jpg', './bgs/amoungstShrubs.jpg',
		'./bgs/sanddunes.jpeg', './bgs/wiley_beach.jpg'
	];

	const lastInteractionTimeRef = useRef(Date.now());
	const lastImageChangeTimeRef = useRef(Date.now());
	const [fadeOut, setFadeOut] = useState(false);

	useEffect(() => {
		const resetTimer = () => {
			lastInteractionTimeRef.current = Date.now();
		};

		window.addEventListener('mousemove', resetTimer);
		window.addEventListener('keydown', resetTimer);

		return () => {
			window.removeEventListener('mousemove', resetTimer);
			window.removeEventListener('keydown', resetTimer);
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			const now = Date.now();
			const idleDuration = now - lastInteractionTimeRef.current;
			const timeSinceLastImage = now - lastImageChangeTimeRef.current;

			if (idleDuration > 18000 && timeSinceLastImage > 18000) {
				setFadeOut(true);

				setTimeout(() => {
					const randomImage = images[Math.floor(Math.random() * images.length)];
					setBackgroundImage(randomImage);
					setFadeOut(false);
					lastImageChangeTimeRef.current = Date.now();// cooldown reset
				}, 1000); // fade out
			}
		}, 2000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const randomImage = images[Math.floor(Math.random() * images.length)];
		setBackgroundImage(randomImage);
	}, []);

	useEffect(() => {
		fetch('https://thinknoise.com/site4.json')
			.then(res => res.json())
			.then(data => {
				setSiteData(data.site);
				if (data.site.experiencePitches?.length) {
					setToggleStates(new Array(data.site.experiencePitches.length).fill(false));
				}
			});
	}, []);

	const handleImageLoad = () => setIsImageLoaded(true);

	const smoothScrollTo = (targetY, duration = 500) => {
		const startY = window.scrollY;
		const diff = targetY - startY;
		const startTime = performance.now();

		const step = (currentTime) => {
			const time = Math.min(1, (currentTime - startTime) / duration);
			const eased = 1 - Math.pow(1 - time, 3); // ease-out cubic
			window.scrollTo(0, startY + diff * eased);

			if (time < 1) {
				requestAnimationFrame(step);
			}
		};

		requestAnimationFrame(step);
	};

	const toggleExpanded = (index) => {
		setToggleStates((prev) => {
			const updated = prev.map((_, i) => i === index ? !prev[i] : false);

			// If the one we're about to open is currently closed, scroll after update
			if (!prev[index]) {
				setTimeout(() => {
					const el = document.getElementById(`experience-${index}`);
					if (el) {
						const offset = 118;
						const y = el.getBoundingClientRect().top + window.scrollY - offset;
						smoothScrollTo(y, 1800);
					}
				}, 200);
			}

			return updated;
		});
	};

	return (
		<div className='main'>
			<div
				className={`image-container ${fadeOut ? 'fade-out' : ''}`}
				style={{
					backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
					backgroundSize: 'cover',
					backgroundPosition: 'right top',
					backgroundRepeat: 'no-repeat'
				}}
			>
				<div className="tooltip-container">
					<span className="tooltip-text">
						All the clothes I am wearing are designed and made by me.&nbsp;
						<Link to='/showcase/nicepants'>NicePants</Link>
					</span>
				</div>
				<img
					src={backgroundImage}
					alt="Background Preload"
					style={{ display: 'none' }}
					onLoad={handleImageLoad}
				/>
			</div>

			<div className="text-overlay">
				<p>{siteData?.mainHeader || 'Seasoned software engineer and innovative musician.'}</p>
				<p className='hero-text'>
					{siteData?.heroDescription?.split(/(\[.*?\]\(.*?\))/g).flatMap((part, i) => {
						const match = part.match(/\[(.*?)\]\((.*?)\)/);
						if (match) {
							return <Link key={i} to={match[2]}>{match[1]}</Link>;
						} else {
							// Split lines and insert <br /> manually
							return part.split('\n').flatMap((line, j, arr) => [
								<span key={`${i}-${j}`}>{line}</span>,
								j < arr.length - 1 ? <br key={`br-${i}-${j}`} /> : null
							]);
						}
					})}
				</p>
			</div>

			<div className="container">
				<section className="leadership-section">
					{siteData?.experiencePitches?.[0] && (
						<div id={`experience-0`}>
							<h1 className="first-headline">{siteData.experiencePitches[0].title}</h1>
							<p>
								{siteData.experiencePitches[0].description}&nbsp;{siteData.experiencePitches[0].expanded}
							</p>
						</div>
					)}
				</section>

				<section className="details-section">
					{siteData?.experiencePitches?.slice(1).map((pitch, index) => (
						<div key={index + 1} id={`experience-${index + 1}`}>
							<h1>{pitch.title}</h1>
							<p>
								{pitch.description}&nbsp;
								<div className={`expandable ${toggleStates[index + 1] ? 'expanded' : ''}`}>
									{pitch.expanded.split('\n').map((line, i) => (
										<span key={i}>
											{line}
											<br />
										</span>
									))}
								</div>
								<button className='moreButton' onClick={() => toggleExpanded(index + 1)}>
									{toggleStates[index + 1] ? 'less' : 'more'}
								</button>
							</p>
						</div>
					))}
				</section>
			</div>
		</div>
	);
};

export default Main;
